import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/card', payload)
    return response || null
  },
  
  async update(payload) {
    let response = await api.put('/admin/card', payload)
    return response
  },

  async delete(payload) {
    const response = await api.delete('/admin/card', {
      data: payload,
    })
    return response
  },

  async getList(params) {
    let response = await api.get('/admin/card', {
      params,
    })
    return response.data.data
  },

  async get(params) {
    let response = await api.get('/admin/card/detail', {
      params,
    })
    return response.data.data.document || null
  },

  async getAll(params) {
    let response = await api.get('/admin/card/all', {
      params,
    })
    return response.data.data.list || null
  },
  async getByCategories(params) {
    let response = await api.get('/admin/card/by-categories', {
      params,
    })
    return response.data.data.list || null
  }
}
