<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Card Management" class="mb-3" />
      </b-col>
    </b-row>
    <data-create-popup title="Create Card"
      :schema="create_schema" :default-data="{image: null, name: null, type: null, categories: []}"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :update_schema="update_schema" :edit-row-fn="updateItem"
    />
  </div>
</template>

<script>
import service from "../service";
import cardCategoryService from '../../card_category/service';
let category_options = [];
const create_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', validate: { required: true } },
      { label: 'Type', field: 'type', validate: { required: true } },
      { label: 'Category', field: 'categories', input_type: 'multiselect', options: category_options}
    ]
  }
];
const update_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', validate: { required: true } },
      { label: 'Type', field: 'type', validate: { required: true } },
      { label: 'Category', field: 'categories', input_type: 'multiselect', options: category_options}
    ]
  }
];
const fields = [
  { label: 'Image', field: 'image', input_type: 'image' },
  { label: 'Name', field: 'name' },
  { label: 'Type', field: 'type' },
  { label: 'Category', field: 'categories', input_type: 'multiselect', options: category_options}
];
export default {

  data() {
    return {
      create_schema,
      update_schema,
      fields,
    };
  },

  created() {
    this.getCardCategory()
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit,
        populate: {path: 'card_categories'}
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'card-detail', params: { id: _id } });
    },
    async getCardCategory(){
      let data = await cardCategoryService.getAll();
      if (category_options.length){
        category_options = [];
      }
      let options = data.map((item)=> {return {text: item.name, value: item._id}});
      category_options.push(...options)
    },
    async updateItem(row){
      await service.update({ data: JSON.stringify(row) });
    },
  },
};
</script>
